import { identity } from "deso-protocol";
import { useContext, useState, useEffect } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import { Link } from "react-router-dom";
import { getDisplayName } from "../helpers";
import logo from "../assets/desosynclogo.jpg";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "components/ui/navigation-menu";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator"
import {
  Avatar,
  AvatarImage,
} from "components/ui/avatar";

export const Nav = () => {
  const { currentUser, alternateUsers, isLoading } = useContext(DeSoIdentityContext);
  const [userPrefs, setUserPrefs] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);

  useEffect(() => {
    if (!isLoading && currentUser) {
      setIsUserLoading(false);
    } else {
      setIsUserLoading(false);
    }
  }, [isLoading, currentUser]);

  useEffect(() => {
    if (!currentUser && alternateUsers.length > 0) {
      identity.setActiveUser(alternateUsers[0].PublicKeyBase58Check);
    }
  }, [isLoading, currentUser, alternateUsers]);

  const getProfilePictureUrl = (user) => {
    return `https://diamondapp.com/api/v0/get-single-profile-picture/${user.PublicKeyBase58Check}?fallback=https://bitclout.com/assets/img/default_profile_pic.png`;
  };

  return (
    <div className={`text-white fade-in ${!isLoading && !isUserLoading && 'visible'}`}>
    <div className="h-full flex justify-between items-center py-4 md:h-16 container">
  <Link to="/" className="pb-1 flex items-center">
    <img src={logo} alt="Home Logo" className="App-logo" />
    <h2 className="text-white ml-4 scroll-m-20 font-extrabold tracking-tight">
      DeSoSync
    </h2>
  </Link>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <NavigationMenu>
          <NavigationMenuList>
            {currentUser ? (
              <>
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="cursor-pointer">
                    <div className="group relative flex items-center cursor-pointer">
                      <Avatar>
                        <AvatarImage src={getProfilePictureUrl(currentUser)} alt={getDisplayName(currentUser)} />
                      </Avatar>
                      <span className="main-nav__username ml-3">
                        {getDisplayName(currentUser)}
                      </span>
                    </div>
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="grid p-1 m-1 md:w-[100px] lg:w-[200px]">
                      {alternateUsers?.map((user) => (
                        <NavigationMenuItem key={user.PublicKeyBase58Check}>
                            <NavigationMenuLink className="cursor-pointer">
                              <div
                                className="group relative flex items-center cursor-pointer block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                                onClick={() => identity.setActiveUser(user.PublicKeyBase58Check)}
                              >
                                <img
                                  src={getProfilePictureUrl(user)}
                                  alt="Alternate User Profile"
                                  className="w-8 h-8 rounded-full mr-2 inline-block"
                                />
                                <div className="text-sm font-medium leading-none">
                                  {getDisplayName(user)}
                                </div>
                              </div>
                            </NavigationMenuLink>
                          </NavigationMenuItem>
                      ))}
                      
                      {alternateUsers && alternateUsers.length > 0 && <Separator />}
                      
                      
                        <NavigationMenuItem className="cursor-pointer">
                          <NavigationMenuLink className="cursor-pointer" onClick={() => identity.login()}>
                            <div className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground">
                              Add another account
                            </div>
                          </NavigationMenuLink>
                        </NavigationMenuItem>
                        <NavigationMenuItem className="cursor-pointer">
                          <NavigationMenuLink onClick={() => identity.logout()}>
                            <div className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground">
                              Logout
                            </div>
                          </NavigationMenuLink>
                        </NavigationMenuItem>
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </>
            ) : (
              <NavigationMenuItem>
                <Button onClick={() => identity.login()}>Login</Button>
              </NavigationMenuItem>
            )}
          </NavigationMenuList>
        </NavigationMenu>
      )}
    </div>
    </div>
  );
};