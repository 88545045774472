import React, { useContext, useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { DeSoIdentityContext } from "react-deso-protocol";
import { getDisplayName } from "../helpers";
import { identity } from "deso-protocol";
import userPrefsStore from 'context/userPrefsStore';
import { Repeat, DollarSign, List, Shield, Video, Link as LinkIcon, Loader2 } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { Switch } from "components/ui/switch"
import TwitterLogin from 'react-twitter-auth';
import axios from 'axios';

const onSuccess = (response, handleSubmitCallback) => {
  response.json().then(body => {
    console.log("Token Data from Twitter:", body);
    handleSubmitCallback(body);  // Use the passed handleSubmit function
  }).catch(error => {
    console.error("Error parsing JSON:", error);
    // You won't have access to setMessage here directly
  });
};

  const onFailed = (error) => {
      console.error("Twitter Login Failed:", error);
  };

  const sendToLambda = async (userDetails) => {
      try {
          await axios.post('API_GATEWAY_ENDPOINT', userDetails);
          console.log('User details sent to Lambda!');
      } catch (error) {
          console.error('Error sending data to Lambda:', error);
      }
  };

export const Home = () => {
  const ICON_SIZE = 48;
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetTwitterAPI, setResetTwitterAPI] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isFormVisible, setFormVisible] = useState(true);
  const [isSyncEnabled, setIsSyncEnabled] = useState(true);  // Default to true (enabled)
  const [hasHashtagError, setHasHashtagError] = useState(false);
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);

  


  const isValidHashtag = (hashtag) => {
    const regex = /^#\w+$/;
    return regex.test(hashtag);
  };



  function FeaturesCards() {
    const features = [
        {
            icon: <Repeat size={ICON_SIZE} />,
            title: "Automatic Synchronization",
            description: "Directly sync your DeSo posts to Twitter."
        },
        {
            icon: <DollarSign size={ICON_SIZE} />,
            title: "Cost-Effective",
            description: "Only $0.005USD per post synced."
        },
        {
            icon: <List size={ICON_SIZE} />,
            title: "Intuitive Auto-Threading",
            description: "Our system automatically adds the 🧵 emoji to threaded posts"
        },
        {
            icon: <Shield size={ICON_SIZE} />,
            title: "Original Content Focus",
            description: "We ensure only your original posts are synced – no re-posts or quoted posts."
        },
        {
            icon: <Video size={ICON_SIZE} />,
            title: "Video Synchronization",
            description: "Seamlessly sync your native DeSo LivePeer videos."
        },
        {
            icon: <LinkIcon size={ICON_SIZE} />,
            title: "Multimedia Embed Support",
            description: "Giphy, YouTube, and Spotify embeds are fully supported."
        }
    ];

    return (
      <div className="flex justify-center m-2 lg:m-10">
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-screen-xl mx-auto">
            {features.map((feature, idx) => (
                <Card className="w-[350px]" key={idx}>
                    <CardHeader className="flex flex-col items-center">
                        {feature.icon}
                        <CardTitle style={{ marginTop: '2rem' }} className="mt-6">{feature.title}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p>{feature.description}</p>
                        {/* Optional: Add any additional content per feature if needed */}
                    </CardContent>
                </Card>
            ))}
        </div>
        </div>
    );
}




const handleSubmitUpdate = async (event) => {
  event.preventDefault();  
  setIsSubmitting(true)
  const hashtag = event.target.sync_hashtag.value;
  // Fetch derived data from local storage
  const desoIdentityUsers = localStorage.getItem("desoIdentityUsers");
  const parsedData = desoIdentityUsers && JSON.parse(desoIdentityUsers);
  const targetUserKey = currentUser && currentUser.PublicKeyBase58Check;
  const derivedPublicKey = parsedData && parsedData[targetUserKey]?.primaryDerivedKey?.derivedPublicKeyBase58Check;
  const derivedSeedHex = parsedData && parsedData[targetUserKey]?.primaryDerivedKey?.derivedSeedHex;
  const derivedDeSo = parsedData && parsedData[targetUserKey]?.primaryDerivedKey?.transactionSpendingLimits?.GlobalDESOLimit;

  if (!derivedPublicKey || !derivedSeedHex || !derivedDeSo) {
      setMessage("Sorry, there was a problem with your DeSo login. Please try again later.");
      setIsSubmitting(false);
      return;
  }

  // Check if derived_seed_hex value is empty
  if (!derivedPublicKey) {
    setMessage("Sorry, there was a problem with your DeSo login. Please try again later.");
    setIsSubmitting(false);
    return;
  }

    if (hashtag !== "" && !isValidHashtag(hashtag)) {
        setMessage("Please ensure your hashtag is in the format: #hashtag");
        setHasHashtagError(true);
        setIsSubmitting(false);
        return;
    } else {
        setHasHashtagError(false); // Reset error when valid
    }

  const response = await fetch('https://9air4x3mn6.execute-api.us-west-2.amazonaws.com/production/updateprefs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      public_key: derivedPublicKey,
      derived_public_key: derivedPublicKey,
      derived_seed_hex: derivedSeedHex,
      sync_hashtag: hashtag,
      derived_deso: derivedDeSo,
      toggle: userPrefs.toggle,
    }),
  });  

  const result = await response.json();
  
  if (response.status === 200) {
    setMessage("Preferences updated successfully");
    setFormSubmitted(true);
    setIsSubmitting(false)
  } else {
    setMessage("Failed to update preferences");
    setIsSubmitting(false)
  }
};




const handleSubmit = async (tokenData, event = null) => {
  try { // Open try block here to catch errors from everything inside
    if (event) event.preventDefault();
    setIsSubmitting(true);
    setMessage("");
    console.log('Submitting form');

    // Collect form and local storage data
    var togglestate = isSyncEnabled ? "enable" : "disable"; // Use var or let or const appropriately
    const formElement = document.getElementById('signupform');
    const formData = new FormData(formElement);
    const dataObject = Array.from(formData.entries()).reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
    dataObject.toggle = togglestate;

      

      // Fetch derived data from local storage
    const desoIdentityUsers = localStorage.getItem("desoIdentityUsers");
    const parsedData = desoIdentityUsers && JSON.parse(desoIdentityUsers);
    const targetUserKey = currentUser && currentUser.PublicKeyBase58Check;
    const derivedPublicKey = parsedData && parsedData[targetUserKey]?.primaryDerivedKey?.derivedPublicKeyBase58Check;
    const derivedSeedHex = parsedData && parsedData[targetUserKey]?.primaryDerivedKey?.derivedSeedHex;
    const derivedDeSo = parsedData && parsedData[targetUserKey]?.primaryDerivedKey?.transactionSpendingLimits?.GlobalDESOLimit;

    if (!derivedPublicKey || !derivedSeedHex || !derivedDeSo) {
        setMessage("Sorry, there was a problem with your DeSo login. Please try again later.");
        setIsSubmitting(false);
        return;
    }
    dataObject.derived_public_key = derivedPublicKey;
    dataObject.derived_seed_hex = derivedSeedHex;
    dataObject.derived_deso = derivedDeSo;


    dataObject.twitter_access_token = tokenData?.access_token;
    dataObject.twitter_access_token_secret = tokenData?.access_token_secret;
    
      console.log("Submitting form more");
      console.log("Form Data:", dataObject);

      // Validate hashtag format
      const hashtag = dataObject['sync_hashtag'];
      if (hashtag !== "" && !isValidHashtag(hashtag)) {
        setMessage("Please ensure your hashtag is in the format: #hashtag");
        setHasHashtagError(true);
        setIsSubmitting(false);
        return;
    } else {
        setHasHashtagError(false); // Reset error when valid
    }
      
     // Fetch and post request
    const response = await fetch("https://9air4x3mn6.execute-api.us-west-2.amazonaws.com/production/newsubscribe", {
      method: "POST",
      body: JSON.stringify(dataObject),
      headers: {
          'Content-Type': 'application/json',
      }
  });

  const responseData = await response.json();
  // Checking the response
  if (responseData.statusCode === 200) {
    setMessage(responseData.message);
    setFormSubmitted(true);
    console.log(responseData);
    setUserPrefs(responseData.userPrefs);
  } else {
    setMessage(responseData.message);
  }
} catch (error) { // Catch any error from the try block
  console.error("Error:", error);
  setMessage("There was an issue connecting to the server. Please try again later.");
} finally { // finally block to set submission state regardless of try/catch result
  setIsSubmitting(false);
}
};
  const handleSuccess = (response) => {
    setIsSubmitting(true)
    onSuccess(response, handleSubmit);
  };
  useEffect(() => {
    console.log("Inside")
    console.log(userPrefs)
  })

  return (
    <div>
    {(!isLoading || userPrefs !== null) && (
      <>
      <h1 className="scroll-m-20 font-extrabold tracking-tight text-5xl md:text-8xl text-center">
                  DeSoSync
      </h1>
      <h1 className="text-1xl font-extrabold leading-tight tracking-tighter lg:text-4xl mt-1 text-center">
                    Bridging DeSo and Twitter 𝕏, One Post at a Time
      </h1>
      </>
    )}

      {!isLoading && !currentUser && (
             <div className="mb-4 text-center">
             <p className="mt-5 mx-2 text-gray-400">We make it easy to sync your DeSo posts to Twitter(𝕏).</p>
             <p className="mt-5 mx-2 mb-3 text-gray-400">Login with your DeSo identity and approve the derived key to proceed.</p>
             <Button onClick={() => identity.login()}>
                 Login
             </Button>
             </div>
          )}
          {!isLoading && currentUser && formSubmitted &&(
            <div className="p-2 mt-2 text-center">
              <p className="mb-2 mx-2">Thank you, your sync preferences have been updated.</p>
              <Button onClick={() => {
                                    setFormSubmitted(false);
                                    setMessage("");
                                }}>
                Change Preferences
              </Button>
            </div>
          )}
          {!isLoading && currentUser && !formSubmitted && userPrefs && !resetTwitterAPI && (
            
            <div>
              
              
            <Card className="p-6 mb-4 w-full lg:w-1/2 mx-auto font-roboto text-white">
            <CardHeader>
              <CardTitle className="text-left">Your Preferences:</CardTitle>
            </CardHeader>
            <CardContent>
            
          <div className="grid w-full items-center gap-1.5">
            <div className="px-4">
              <form onSubmit={handleSubmitUpdate}>
                {/* Display user preferences and DeSo left */}
                <div className=" text-center">
                <p className="mb-3">Remaining Derived DeSo: Đ{(Number(userPrefs.derived_deso) / 1e9).toFixed(2)}</p>
                </div>
                
                <div className="grid w-full gap-1.5 mt-4">
                  <Label className="text-left" htmlFor="sync_hashtag">Only sync post when this hashtag is present:</Label>
                  <Input id="sync_hashtag" name="sync_hashtag" type="text" placeholder="Example: #hashtag" defaultValue={userPrefs.syncHashtag ? userPrefs.syncHashtag : ""}  style={hasHashtagError ? { borderColor: 'red' } : {}} />
                </div>
          
                <div className="flex flex-row items-center justify-between rounded-lg border p-4 mt-3 mb-3">
                  <Label className="text-base text-1xl">
                    Enable Sync
                  </Label>
                  <Switch 
                    name="toggle" 
                    id="toggle" 
                    checked={userPrefs.toggle} 
                    onClick={() => setUserPrefs({...userPrefs, toggle: !userPrefs.toggle})}
                  />
                </div>
                <div className="container m-auto grid grid-cols-1 gap-4">
    <Button 
        type="button"
        variant="outline" 
        onClick={() => {
            setResetTwitterAPI(true);
            setMessage("");
        }}
        disabled={isSubmitting}
    >
        Update Twitter Authorization
    </Button>
    <Button 
        type="submit" 
        disabled={isSubmitting}
    >
        {isSubmitting 
        ? (
            <>
                <Loader2 className="animate-spin" />
                Please Wait
            </>
            )
        : 'Update Preferences'}
    </Button>
</div>
           

              </form>
              {message && <p className="mt-2 text-red-600">{message}</p>}
            </div>
            </div>
            </CardContent>
            </Card>
            </div>
          )}
          {!isLoading && currentUser && !formSubmitted && (!userPrefs || resetTwitterAPI) && (
            <div className="p-4 text-center">
              {(!resetTwitterAPI) ? 
              <>
              <p className="p-4 mx-2">Now that you're logged in, fill in your preferences and click "Authorize with Twitter" to setup sync.</p> </> : <> </>}
              
                    <Card className="p-4 w-full lg:w-1/2 mx-auto font-roboto text-white">
                  
                
                  <form id="signupform" onSubmit={handleSubmit} method="post" className="space-y-4">
                    <input type="hidden" name="public_key" value={currentUser.PublicKeyBase58Check} />
                
                  
                  
                  <CardHeader>
        <CardTitle>Sync Preferences:</CardTitle>
      </CardHeader>
      <CardContent>
                <div>
                
                <div className="grid w-full items-center gap-1.5">
                           <Label htmlFor="twitter_api_id">Only sync post when this hashtag is present:</Label>
                           <Input 
                             id="sync_hashtag" 
                             name="sync_hashtag" 
                             type="text" 
                             defaultValue={userPrefs?.syncHashtag || ""} 
                             placeholder="Example: #hashtag" 
                             style={hasHashtagError ? { borderColor: 'red' } : {}} 
                           />
                    </div>
                  </div>
                  
          <div className="mt-3">
              <div className="space-y-4">
                    <div className="flex flex-row items-center justify-between rounded-lg border p-4">
                      <div className="space-y-0.5">
                        <Label className="text-base text-1xl">
                          Enable Sync
                        </Label>

                      </div>
                        <Switch 
                           name="toggle" 
                           id="toggle" 
                           checked={isSyncEnabled} 
                           onClick={() => setIsSyncEnabled(!isSyncEnabled)}
                        />
                    </div>
              </div>
              </div>
              </CardContent>
           
              {(!resetTwitterAPI) ? <div className="mt-3">
                <Button 
                    type="submit" 
                    
                    disabled={isSubmitting}
                >
                    {isSubmitting 
                    ? (
                      <>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          Please Wait
                      </>
                      )
                    : <TwitterLogin
                    loginUrl="https://9air4x3mn6.execute-api.us-west-2.amazonaws.com/production/login"
                    onFailure={onFailed}
                    onSuccess={handleSuccess}
                    requestTokenUrl="https://9air4x3mn6.execute-api.us-west-2.amazonaws.com/production/request-token"
                    showIcon={false}
                    >
                      Authorize with Twitter
                  </TwitterLogin>}
                </Button>
                </div> : <><div className="container m-auto grid grid-cols-2 md:grid-cols-2 gap-4"><div className="mt-3">
                                                     <Button 
                                               type="button"
                                               variant="outline" 
                                               onClick={() => {
                                                   setResetTwitterAPI(false);
                                                   setMessage("");
                                               }}>Cancel
                                           </Button>
                                          </div>
                <div className="mt-3">
                <Button 
                    type="submit" 
                    
                    disabled={isSubmitting}
                >
                    {isSubmitting 
                    ? (
                      <>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          Please Wait
                      </>
                      )
                    : <TwitterLogin
                    loginUrl="https://9air4x3mn6.execute-api.us-west-2.amazonaws.com/production/login"
                    onFailure={onFailed}
                    onSuccess={handleSuccess}
                    requestTokenUrl="https://9air4x3mn6.execute-api.us-west-2.amazonaws.com/production/request-token"
                    showIcon={false}
                    >
                      Authorize with Twitter
                  </TwitterLogin>}
                </Button>
                </div>
                </div>
                </>
                }
  
                </form>
                {message && <p className="mt-2 text-red-600">{message}</p>}
              </Card> 
            </div>
          )}
         {(!isLoading) ? <FeaturesCards /> : ""}
    </div>
  );
}