import { configure } from "deso-protocol";
import { useContext, useEffect, useState } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import userPrefsStore from 'context/userPrefsStore';
import { Loader2 } from 'lucide-react';
import { Outlet } from "react-router-dom";
import { Nav } from "../components/nav";

configure({
  spendingLimitOptions: {
    GlobalDESOLimit: 1000000000, // 1.00 DESO
    TransactionCountLimitMap: {
      BASIC_TRANSFER: "UNLIMITED",
    },
  },
});


export const Root = () => {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [ isUserLoading, setIsUserLoading ] = useState(true);
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);
  const [loading, setLoading] = useState(true);

  
  const getDerivedData = () => {
    if (currentUser && currentUser.PublicKeyBase58Check) {
      const desoIdentityUsers = localStorage.getItem("desoIdentityUsers");
      const parsedData = JSON.parse(desoIdentityUsers);
      const targetUserKey = currentUser.PublicKeyBase58Check;

      return {
        derivedPublicKey: parsedData[targetUserKey]['primaryDerivedKey'].derivedPublicKeyBase58Check,
        derivedSeedHex: parsedData[targetUserKey]['primaryDerivedKey'].derivedSeedHex,
        derivedDeSo: parsedData[targetUserKey]['primaryDerivedKey']['transactionSpendingLimits'].GlobalDESOLimit
      };
    }
    return { derivedPublicKey: null, derivedSeedHex: null, derivedDeso: null };
  }

  useEffect(() => {
    setLoading(true);
    if (currentUser) {
      fetch('https://9air4x3mn6.execute-api.us-west-2.amazonaws.com/production/getprefs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          public_key: currentUser.PublicKeyBase58Check,
          derived_public_key: getDerivedData().derivedPublicKey,
          derived_seed_hex: getDerivedData().derivedSeedHex,
        }),
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (data.statusCode !== 200) {
            console.log('Error:', data.message);
            setUserPrefs(false);
          } else {
            const bodyData = JSON.parse(data.body);
            const newUserPrefs = {
              publicKey: bodyData.publicKey,
              derived_deso: bodyData.derivedDeso,
              sync_hashtag: bodyData.syncHashtag,
              toggle: bodyData.toggle,
            };
            setUserPrefs(newUserPrefs);
            console.log(newUserPrefs);
            console.log("We did it!")
          }
        })
        .catch(error => {
          console.log('Error:', error);
          setUserPrefs(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setUserPrefs(false);
      console.log('isUserLoading:', isUserLoading);
    }
  }, [currentUser]);

  return (
  <>
    <Nav />
    <div role="main" className="main-content">
      <div className={`main-content ${!loading ? 'loaded' : ''}`}>
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader2 className="mr-2 h-10 w-10 animate-spin" />
            <div className="4xl">Loading...</div>
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  </>
);
  
};
